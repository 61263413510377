/* vertical line styling for body text */
.vertical-line-container {
  position: relative;
  /* Make the container a positioning context for the absolute line */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.vertical-line {

  position: absolute;
  /* Position the line absolutely */
  top: 0;
  /* Place it at the top of the container */
  left: 50%;
  /* Position it in the center horizontally */
  width: 3px;
  height: 180px;
  background-color: #000;
  transform: translateX(-50%);
  /* Center it perfectly */
  z-index: 1;
  /* Set a higher z-index to ensure it's above other elements */
}

/* form styling */
.input-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.input-box::placeholder {
  font-size: 16px;
}

.input-box {
  border: 2px solid black;
  width: 300px;
  padding-left: 5px;

}

.text-area {
  width: 300px;
  height: 100px;
}

.text-align-left {
  text-align: left;
}

/* slide in left/right animations */
.slide-in-left {
  transform: translateX(-100%);
  /* Move the element off-screen to the left */
}

.slide-in-right {
  transform: translateX(200%);
  /* Move the element off-screen to the right */
}

.visible.slide-in-left,
.visible.slide-in-right {
  transform: translateX(0);
  /* Slide the element back to its original position */
  transition: transform .8s ease-in;
  /* Use CSS transition for smooth sliding */
}

/* LANDING SECTION FADE IN */
.fade-in {
  opacity: 0;
  animation: fadeIn 2s ease-in-out forwards;
}

.fade-in2 {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}