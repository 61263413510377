.container {
  position: relative;
  /* Relative to this, the background and overlay will be positioned */
  height: 100vh;
  /* 100% of the viewport height */
  width: 100%;
  /* 100% of the container width */
  overflow: hidden;
  /* Hide any overflowing content */
}

.background-image {
  position: absolute;
  /* Absolute positioning */
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background-image: url('your-image-url-here.jpg'); */
  /* Replace with your image URL */
  background-size: cover;
  /* Cover the entire area */
  background-position: center;
  /* Center the image */
  z-index: 1;
  /* Behind the overlay */
}

.overlaycareers {
  position: absolute;
  /* Absolute positioning */
  top: 50%;
  left: 0;
  width: 100%;
  /* Span the entire width */
  height: 2.6in;
  /* Span the entire height */
  background-color: rgba(128, 128, 128, 0.5);
  /* Grey with 50% opacity */
  z-index: 2;
  /* In front of the background image */
  display: flex;
  flex-direction: row;
  /* Using Flexbox for */
  justify-content: center;
  /* Horizontally centering the text */
  align-items: center;
  /* Vertically centering the text */
}

.overlaycareers p {
  /* font-size: 2em; */
  /* Large text */
  color: white;
  /* White text */
}

.overlayAboutUsMobile {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 60vh;
  /* For testing, set a definite height */
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* Padding so content doesn't touch the edges */
}

.overlayServicesMobile {
  position: absolute;
  top: 15%;
  left: 0;
  width: 100%;
  height: 70vh;
  /* For testing, set a definite height */
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* Padding so content doesn't touch the edges */
}

.overlayCareersMobile {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 40vh;
  /* For testing, set a definite height */
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* Padding so content doesn't touch the edges */
}

.overlayContactUsMobile {
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 40vh;
  /* For testing, set a definite height */
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* Padding so content doesn't touch the edges */
}





.back-button {
  position: absolute;
  top: 10px;
  /* Adjust as needed */
  left: 10px;
  /* Adjust as needed */
  background: transparent;
  border: none;
  font-size: 1.5em;
  /* Adjust as needed */
  color: white;
  /* You can choose any color */
  cursor: pointer;
  z-index: 3;
  /* Above the overlay */
}