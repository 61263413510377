/* styles.css (or any appropriate CSS file) */

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 880px;
  /* Adjust the height as needed */
  top: 0;
}

.parallax-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
