.sticky {
  position: sticky;
  top: 0;
  z-index: 100;
  /* transition: transform 0.3s ease; */
transition: opacity 0.6s ease-in-out;
}

.visible {
  opacity: 1;
  /* Show the header when visible */
}

.hidden {
opacity: 0;
  /* Hide the header by moving it off the screen */
}