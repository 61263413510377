/* disables scrolling while modal is open */
body.modal-open, html.modal-open {
  overflow: hidden;
}

/* gallery image overlay styling */
.image-container {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.image-container img {
  width: 100%;
  max-height: 400px;
  display: block;
  object-fit: cover;
  object-position: center;
}

.overlay {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.overlay img {
  max-width: 200px;
}

.overlay p.image-title {
  color: #fff;
  font-size: 20px;
  margin-top: 10px;
  font-style: italic;
}

.image-container:hover .overlay {
  opacity: 0;
}

.image-container:hover {
transform: scale(1.01);
cursor: pointer;
}

/* alternate gallery grid styling, alternative to chakra inline */
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

/* overlay slide animations */
.overlay-slide-left {
  position: relative;
  left: -100%;
  /* Start with the element off-screen to the left */
  transition: left 0.5s ease;
  /* Add a transition for smooth sliding */
}

.overlay-slide-left:hover {
  left: 0;
  /* Slide the element in to the left position on hover */
}

/* gallery animations */
.slide-left {
  animation: slideInFromLeft 0.5s;
}

.slide-right {
  animation: slideInFromRight 0.5s;
}

/* Make the modal content flexible */
.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* take the full height of the modal */
}

/* Style the image */
.modalImage {
  max-width: 100%;
  /* restrict the width to the modal's width */
  max-height: 100%;
  /* restrict the height to the modal's height */
  width: auto;
  /* adjust the width based on the original image's aspect ratio */
  height: auto;
  /* adjust the height based on the original image's aspect ratio */
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";